<template>
  <div v-if="isVisible" class="fixed-qr-code">
    <span class="close-icon" @click="closeQrCode">&times;</span>
    <img src="@/assets/gbwhatsapp-qr-code-chat.webp" alt="QR Code for download GB WhatsApp" />
  </div>
</template>
<script>
export default {
  name: "FixedQrCode",
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    closeQrCode() {
      this.isVisible = false;
    }
  },
};
</script>

<style lang="scss">
.fixed-qr-code {
  position: fixed;
  bottom: 35px;
  right: 30px;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  img {
    border-radius: 5px;
    max-width: 190px;
  }
  .close-icon {
    position: absolute;
    top: -4px;
    right: 3px;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }
}
</style>    