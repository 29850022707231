<template>
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
  <div class="inside-navigation grid-container">
    <div class="navigation-branding">
      <div class="site-logo">
        <a href="https://www.gbwhatsapp.chat/" title="GBWhatsApp" rel="home">
          <img class="header-image is-logo-image" width="40" height="40" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
        </a>
      </div>
      <p class="main-title" itemprop="headline">
        <a href="https://www.gbwhatsapp.chat/" rel="home">
          GBWhatsApp
        </a>
      </p>
    </div>

    <ul id="menu-primary-menu" class="menu sf-menu mobile-menu-page" v-show="isMenuClicked">
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/" aria-current="page">GB WhatsApp</a>
        </li>
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/gb-whatsapp-for-pc/" aria-current="page">For PC</a>
        </li>
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/gbwhatsapp-login-problem/" aria-current="page">Login Problem</a>
        </li>
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <a href="https://www.gbwhatsapp.chat/downloadpage/">{{translation[selectedLanguage].download}}</a>
        </li>
        <!-- <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <a href="https://www.gbwhatsapp.chat/whatsapp-mods/">{{translation[selectedLanguage].mod}}</a>
        </li> -->
        <li id="menu-item-34 mods" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mods">
          <a href="https://www.gbwhatsapp.chat/whatsapp-mods/">{{ this.translation[selectedLanguage].mod }}</a>
          <!-- <router-link :to="{ name: `${selectedLanguage}-mod` }">{{ this.translation[selectedLanguage].mod }}</router-link> -->
          <img src="@/assets/menu.svg" alt="" @click="isModClicked=!isModClicked" v-if="!isModClicked">
          <img src="@/assets/menu.svg" alt="" @click="isModClicked=!isModClicked" v-else>
        </li>
        <li class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mod-item"
            v-for="(data, i) in modsList" :key="i" v-show="isModClicked">
          <!-- <router-link :to="{ name: `en-${data.name}` }">{{ data.title }}</router-link> -->
           <a :href='data.link'>{{ data.title }}</a>
        </li>
        <!-- @click="gotodownload()" -->
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <a href="https://www.gbwhatsapp.chat/blogs/">{{translation[selectedLanguage].blogs}}</a>
        </li>
      </ul>

    <div id="primary-menu" class="main-nav">
      <div class="mobile-menu" v-if="$global.isMobileDevice()">
        <div class="language-selector">
          <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
            <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang" :label="data.val">
              {{ data.val }}
            </option>
          </select>
        </div>
        <div class="menu-toggle" @click="isMenuClicked=!isMenuClicked">
          <img src="@/assets/menu.svg" alt="menu button" v-if="!isMenuClicked">
          <img src="@/assets/xmark.svg" alt="close button" v-else>
        </div>
      </div>

      <ul v-else id="menu-primary-menu" class="menu sf-menu">
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/" aria-current="page">GB WhatsApp</a>
        </li>
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/gb-whatsapp-for-pc/" aria-current="page">For PC</a>
        </li>
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <a href="https://www.gbwhatsapp.chat/gbwhatsapp-login-problem/" aria-current="page">Login Problem</a>
        </li>
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <a href="https://www.gbwhatsapp.chat/downloadpage/">{{translation[selectedLanguage].download}}</a>
        </li>
        <li id="menu-item-34 mods" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mods"
            @mouseover="isHovered=true" @mouseleave="isHovered=false">
           <a href="https://www.gbwhatsapp.chat/whatsapp-mods/">{{ this.translation[selectedLanguage].mod }}</a>
          <div class="mod-list" v-show="isHovered">
            <div class="mod-item" v-for="(data, i) in modsList" :key="i">
              <a :href='data.link' class="mod-title">{{ data.title }}</a>
              <!-- <router-link :to="{ name: `${selectedLanguage}-${data.name}` }" class="mod-title" v-if="data.name=='plus' && (selectedLanguage=='ar'||selectedLanguage=='es')">{{ data.title }}</router-link>
              <router-link :to="{ name: `${data.name}Mod` }" class="mod-title" v-else>{{ data.title }}</router-link> -->
            </div>
          </div>
        </li>
        <!-- @click="gotodownload()" -->
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <a href="https://www.gbwhatsapp.chat/blogs/">{{translation[selectedLanguage].blogs}}</a>
        </li>
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <div class="language-selector">
            <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
              <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                {{ data.val }}
              </option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
export default {
props: {
  selectedLanguage: {
    type: String,
    default: 'en',
  },
},
data() {
  return {
    isMenuClicked: false,
    isHovered: false,
    isModClicked:false,
    lang: [{
        lang: 'en',
        val: '🇺🇸 English'
      },
      {
        lang: 'ar',
        val: '🇦🇪 اَلْعَرَبِيَّةُ'
      },
      {
        lang: 'id',
        val: '🇮🇩 Bahasa Indonesia'
      },
      {
        lang: 'po',
        val: '🇵🇹 Português'
      },
      {
        lang: 'tr',
        val: '🇹🇷 Türkçe'
      },
      {
        lang: 'in',
        val: '🇮🇳 हिंदी'
      },
    ],
    modsList: [
      {
          title: 'FM WhatsApp',
          name: 'fm',
          link:'https://www.gbwhatsapp.chat/fmwhatsapp-apk-download/',
      },
      // {
      //     title: 'GBWhatsApp iOS',
      //     name: 'ios'
      // },
      {
          title: 'YO WhatsApp',
          name: 'yo',
          link:'https://www.gbwhatsapp.chat/yowhatsapp-apk-download/',
      },
      {
          title: 'JT WhatsApp',
          name: 'jt',
          link:'https://www.gbwhatsapp.chat/jtwhatsapp-apk-download/',
      },
      {
          title: 'WhatsApp Plus',
          name: 'plus',
          link:'https://www.gbwhatsapp.chat/whatsapp-plus-apk-download/',
      },
      {
          title: 'OG WhatsApp',
          name: 'og',
          link:'https://www.gbwhatsapp.chat/ogwhatsapp-apk-download/',
      },
      // {
      //     title: 'OB WhatsApp',
      //     name: 'ob'
      // },
      // {
      //     title: 'GB WhatsApp Pro',
      //     name: 'pro'
      // }
    ],
    translation: {
      en: {
        home: 'Home',
        download: 'Download',
        blogs: 'Blogs',
        mod: 'WhatsApp Mods',
      },
      ar: {
        home: 'الصفحة الرئيسية',
        download: 'تحميل',
        blogs: 'المدونات',
        mod: 'مودات واتس اب',
      },
      id: {
        home: 'Beranda',
        download: 'Unduh',
        blogs: 'Blog',
        mod: 'Mod WhatsApp',
      },
      po: {
        home: 'Casa',
        download: 'Baixar',
        blogs: 'Blogs',
        mod: 'Mods do WhatsApp',
      },
      tr: {
        home: 'Ana Sayfa',
        download: 'İndir',
        blogs: 'Bloglar',
        mod: 'WhatsApp Modları',
      },
      in: {
        home: 'घर',
        download: 'डाउनलोड',
        blogs: 'ब्लॉग',
        mod: 'व्हाट्सएप मोड्स',
      },
    },
  }
},
methods: {
  changeLanguage(lang) {
    console.log(lang);
    // 处理语言选择变化
    // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
    // this.$router.push({
    //   name: `${this.selectedLanguage}-gb`
    // });
    if (this.selectedLanguage === 'en') {
      window.location.href = '/';
    } else{
      window.location.href = `/${this.selectedLanguage}`;
    }
  },
},
}
</script>

<style lang="scss" scoped>
.mods {
position: relative;
.mod-list{
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 1001;
  background: #212121;
  color: #ffffff;
  .mod-item {
    padding: 12px;
    border-bottom: 1px solid #ffffffac;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #000000;
    }
    .mod-title {
      line-height: 22px;
      padding: 5px 0;
    }
  }
}
}

.main-nav {
  .menu {
  list-style-type: none !important;
  margin: 0 !important;
}
}

@media screen and (max-width: 768px) {
.inside-navigation {
  position: relative;
  .mobile-menu-page {
    width: 100%;
    position: absolute;
    top:100%;
    z-index: 1001;
    margin: 0 !important;
    .menu-item {
      padding: 10px 0;
      margin-bottom: 0 !important;
      background: #323232;
      a {
        font-size: 15px;
        text-align: center;
        color: #ffffff;
        display: block;
      }
      &.mods {
        position: relative;
        img {
          width: 30px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          padding: 7px;
        }
      }
      &.mod-item {
        background: #434343;
        border: 0;
      }
    }
  }
}
.main-nav {
flex: 1;
.mobile-menu{
  display: flex;
  justify-content: space-evenly;
  position: relative;
  .language-selector {
    width: 60%;
    #language-select {
      width: 100%;
    }
  }
  .menu-toggle {
    display: block;
    width: 25px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
}
}
.main-navigation .main-nav ul li a{
  padding-right: 20px;
  padding-left: 0;
  line-height: 60px;
}
</style>
