export default{
  bloglist: [
    {
      title: 'How to use same WhatsApp Account on Different phone?',
      poster: require('@/assets/blog-1-x1.jpg'),
      desc: "If you are using WhatsApp and want to use the same account on a different phone, the answer is 'No' But if you are using GB WhatsApp and want to use the same account on a different phone, the answer is ",
      link: 'https://www.gbwhatsapp.chat/blog-1/',
      date: '2023-04-28',
      category: 'Tips and Resources'
    },
    {
      title: 'GB WhatsApp Data Recovery Way',
      poster: require('@/assets/blog-3.webp'),
      desc: 'Many users opt to use GB WhatsApp over the standard version due to its additional features, especially for important conversations related to work or business. Consequently, the conversations and other data stored in GB Whats',
      link: 'https://www.gbwhatsapp.chat/blog-2/',
      date: '2023-08-05',
      category: 'Tips and Resources'
    },
    {
      title: 'GBWhatsApp Channels',
      poster: require('@/assets/blog-cv-3-x1.webp'),
      desc: "Great news, channel function is finally here on GBWhatsApp. It's like Telegram Channel, where you can share information and connect with your audience. That means you can enjoy all the channel goodness right from your favorite modified WhatsApp version. So, let's dive into the world of WhatsApp channels and discover how they can revolutionize your chatting experience and boost your business.",
      link: 'https://www.gbwhatsapp.chat/blogs/GBWhatsApp-Channels/',
      date: '2023-12-11',
      category: 'Tips and Resources'

    },
    {
      title: 'All Your Questions About GB WhatsApp',
      poster: require('@/assets/blog-cv-4-x1.webp'),
      desc: 'GB WhatsApp is also known as " WhatsApp GB", "Ogmods" app which has more unique features of WhatsApp. These include features such as attractive themes, the ability to hide internet status, enabling airplane mode, and more.',
      link: 'https://www.gbwhatsapp.chat/blog-4/',
      date: '2024-03-23',
      category: 'Troubleshooting'

    },
    {
      title: "The 10 Best Apps for Spying someone else's WhatsApp",
      poster: require('@/assets/OTgyNGRhYzA4ZDU2NGEyYjM3ZjU2MDE0NzE4ZDIxZDJfeGNTRDRhandPMndkTUF1V2MwQVNreDZLdExXMUJPMGdfVG9rZW46SUt2SWJHOTVOb3hpR0t4aUdYTmMwMDBCbm9nXzE3MTg3ODM1MjA6MTcxODc4NzEyMF9WNA-x1.webp'),
      desc: 'This is one of the most frequently asked questions on social media platforms. It is evident that, regardless of the reasons, people are very eager to know how to spy',
      link: 'https://www.gbwhatsapp.chat/blog-5/',
      date: '2024-09-02',
      category: 'Tips and Resources'

    },
    {
      title: "Error: You Need The Official WhatsApp to Use This Account",
      poster: require('@/assets/blog6-1-x1.webp'),
      desc: "Have you ever received this error message: \"You Need The Official WhatsApp to Use This Account\"? Many users of WhatsApp Mods like FM WhatsApp, WhatsApp Plus, OB WhatsApp, and other mods with smaller user bases have encountered this issue.",
      link: 'https://www.gbwhatsapp.chat/fix-you-need-official-whatsapp/',
      date: '2024-11-06',
      category: 'Troubleshooting'
    },
    {
      title: "How to Hide Online Status on GB Whatsapp",
      poster: require('@/assets/blog7-1-x1.webp'),
      desc: "GB WhatsApp is a popular modified version of the official WhatsApp application, offering enhanced features to help users hide online status. Unlike the original app",
      link: 'https://www.gbwhatsapp.chat/how-to-hide-online-status-on-gb-whatsapp/',
      date: '2025-01-23',
      category: 'Tips and Resources'
    },
    {
      title: "GBWhatsApp Not Working: Reasons and Solutions",
      poster: require('@/assets/gbwhatsapp-not-working-x1.webp'),
      desc: "GBWhatsApp is a widely popular modified version of the official WhatsApp application, offering a host of advanced features. However, users may occasionally encounter issues where GBWhatsApp is not sending or receiving messages.",
      link: 'https://www.gbwhatsapp.chat/gbwhatsapp-not-working/',
      date: '2025-02-11',
      category: 'Troubleshooting'
    },
    {
      title: "How to Delete GB WhatsApp on Android Completely",
      poster: require('@/assets/uninstall-gbwhatsapp-x1.webp'),
      desc: "GB WhatsApp (package name: com.gbwhatsapp) is a popular modded version of WhatsApp with enhanced features. However, if you want to uninstall it completely—including all related files and folders—you need to follow the right steps",
      link: 'https://www.gbwhatsapp.chat/uninstall-gb-whatsapp/',
      date: '2025-03-12',
      category: 'Troubleshooting'
    },
    {
      title: "How to Use GB WhatsApp Without Being Banned",
      poster: require('@/assets/how-to-use-gbwhatsapp-without-banned-x1.webp'),
      desc: "GB WhatsApp is a modified version of the official WhatsApp. While it is a favorite among users who seek more customization, many have faced the issue of getting banned by WhatsApp. If you want to continue using GB WhatsApp without being banned, it is essential to understand the risks and follow safe practices. This blog will guide you through the ban issue, how to use GB WhatsApp safely, and whether the app is still functional.",
      link: 'https://www.gbwhatsapp.chat/how-to-use-gb-whatsapp-without-being-banned/',
      date: '2025-03-18',
      category: 'Troubleshooting'
    },
    {
      title: "Methods to Hide Chat in GB WhatsApp 2025",
      poster: require('@/assets/hidechatgbwhatsapp.webp'),
      desc: "How to lock or hide chats in GB WhatsApp? Here we introduced simple but useful steps to lock GB WhatsApp chats to protect your privacy.",
      link: 'https://www.gbwhatsapp.chat/lock-chat-in-gbwhatsapp/',
      date: '2025-03-25',
      category: 'GB WhatsApp Update'
    },
    {
      title: "GB WhatsApp vs. FM WhatsApp: Key Differences & Features Compared",
      poster: require('@/assets/gbwhatsapp-vs-fmwhatsapp.webp'),
      desc: "Discover the key differences between GB WhatsApp and FM WhatsApp. Compare features and performance to choose the best WhatsApp mod for your needs.",
      link: 'https://www.gbwhatsapp.chat/gbwhatsapp-vs-fmwhatsapp/',
      date: '2025-04-01',
      category: 'Tips and Resources'
    },

  ]
}

