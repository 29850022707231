<template>
  <div class="recent-post">
    <div class="post" v-for="post in [update, tip, trouble]" :key="post.title">
      <a class="category-title" :href="`https://www.gbwhatsapp.chat/category/${formatCategory(post.category)}/`">
        {{ post.category }}
      </a>
      <a class="post-img" :href="post.link">
        <img :src="post.poster" loading="lazy" :alt="post.title">
      </a>
      <div class="post-content">
        <a class="blog-list-title" :href="post.link">
          {{ post.title }}
        </a>
        <div class="post-date">
          {{ post.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bloglist from '@/utlis/blogList.js';

export default {
  data() {
    return {
      blog: bloglist.bloglist || []
    };
  },
  computed: {
    update() {
      return this.getNewestPostByCategory('GB WhatsApp Update');
    },
    tip() {
      return this.getNewestPostByCategory('Tips and Resources');
    },
    trouble() {
      return this.getNewestPostByCategory('Troubleshooting');
    }
  },
  methods: {
    getNewestPostByCategory(category) {
      if (!Array.isArray(this.blog) || this.blog.length === 0) return {};
      return [...this.blog].reverse().find(post => post.category === category) || {};
    },
    formatCategory(category) {
      return category.toLowerCase().replace(/\s+/g, '-'); // 转换小写并替换空格为 "-"
    }
  }
};
</script>


<style lang="scss" scoped>
.recent-post {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 30px;

  .post {
    margin-left: 10px;
    padding: 15px 20px;
    box-shadow: 0 2px 10px 4px rgba(183, 183, 183, 0.05);
    border-radius: 5px;
    background-color: #3d464d;

    &:first-child {
      margin-left: 0;
      margin-right: 10px;
    }

    .category-title {
      display: block;
      font-size: 23px;
      font-weight: 600;
      color: #20a8ab;
      margin-bottom: 12px !important;
    }

    .post-img {
      img {
        height: 160px;
        object-fit: cover;
      }
    }

    .post-content {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 12px;

      .blog-list-title {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #20a8ab;
        }
      }

      .post-descript {
        font-size: 16px;
        line-height: 1.55;
        color: #eee;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .post-date {
        font-size: 14px;
        color: #eee;
      }
    }
  }
}

@media (max-width: 768px) {
  .recent-post {
    grid-template-columns: 1fr;

    .post {
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 20px;
      padding: 20px;

      .post-img {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
